import { Heading, LinkText, Paper, Text } from '@mediahuis/chameleon-react';
import { format } from 'date-fns';
import React from 'react';
import { List, ListItem } from '../../../../components';
import { language } from './language';

const Summary = ({
  order,
  formula,
  paymentValidation,
  voucherCode,
  voucherId,
}) => {
  const { getPaymentUrl } = language;
  const { subscriptionTypes } = formula;

  const formattedStartDate = format(
    new Date(order.creationDate),
    'dd-MM-yyyy HH:mm:ss',
  );

  return (
    <Paper p={4} mb={5}>
      <Heading fontFamily="primary" level={6}>
        Summary
      </Heading>
      <List>
        {order && (
          <ListItem data-testid="orderBrand">
            <Text size="Caption2">
              <strong>Brand:</strong> <Text size="Caption2">{order.brand}</Text>
            </Text>
          </ListItem>
        )}
        {formattedStartDate && (
          <ListItem data-testid="orderStartDate">
            <Text size="Caption2">
              <strong>Start date:</strong>{' '}
              <Text size="Caption2">{formattedStartDate}</Text>
            </Text>
          </ListItem>
        )}
        {formula && (
          <ListItem data-testid="orderProduct">
            <Text size="Caption2">
              <strong>Product:</strong>{' '}
              <Text size="Caption2">
                <LinkText
                  href={`/subscriptionformula/edit/${formula.id}`}
                  target="_blank"
                >
                  {formula.title}
                </LinkText>
              </Text>
            </Text>
          </ListItem>
        )}
        {subscriptionTypes && (
          <ListItem data-testid="orderType">
            <Text size="Caption2">
              <strong>Type:</strong>{' '}
              <Text size="Caption2">
                <LinkText
                  href={`/subscriptiontype/edit/${subscriptionTypes[0].id}`}
                  target="_blank"
                >
                  {subscriptionTypes[0].title}
                </LinkText>
              </Text>
            </Text>
          </ListItem>
        )}
        {order && order.formulaOfferId && (
          <ListItem data-testid="formulaOfferId">
            <Text size="Caption2">
              <strong>Offer:</strong>
              <Text size="Caption2">
                <LinkText
                  href={`/offer/edit/${order.formulaOfferId}`}
                  target="_blank"
                >
                  {order.formulaOfferId}
                </LinkText>
              </Text>
            </Text>
          </ListItem>
        )}
        {formula && (
          <ListItem data-testid="orderDuration">
            <Text size="Caption2">
              <strong>Duration:</strong>{' '}
              <Text size="Caption2">
                {formula.duration} {formula.durationType}
              </Text>
            </Text>
          </ListItem>
        )}
        {order && (
          <ListItem data-testid="orderState">
            <Text size="Caption2">
              <strong>State:</strong> <Text size="Caption2">{order.state}</Text>
            </Text>
          </ListItem>
        )}
        {order && order.deviceType && (
          <ListItem data-testid="orderDeviceType">
            <Text size="Caption2">
              <strong>DeviceType:</strong> <Text size="Caption2">{order.deviceType}</Text>
            </Text>
          </ListItem>
        )}
        {paymentValidation?.data?.paymentId && (
          <ListItem data-testid="orderState">
            <Text size="Caption2">
              <strong>PaymentId:</strong>{' '}
            </Text>
            {paymentValidation.data.method === 'DirectDebit' || paymentValidation.data.method === 'WireTransfer' ? (
              <div style={{ overflow: 'auto' }}>
                <Text size="Caption2">{paymentValidation.data.paymentId}</Text>
              </div>
            ) : (
              <LinkText
                href={getPaymentUrl(
                  paymentValidation.data.paymentId,
                  order.brand,
                )}
                target="_blank"
              >
                <Text size="Caption2">{paymentValidation.data.paymentId}</Text>
              </LinkText>
            )}
          </ListItem>
        )}
        {voucherCode && voucherId && (
          <ListItem data-testid="voucherCode">
            <Text size="Caption2">
              <strong>Vouchercode:</strong>{' '}
            </Text>

            <LinkText href={`/vouchers/edit/${voucherId}`} target="_blank">
              <Text size="Caption2">{voucherCode}</Text>
            </LinkText>
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default Summary;
